import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'

const StartPage = ({ data }) => (
  <Layout>
    <div className="start">
      <div className="start__text">
        <h1>
          Ecology <span className="smallcaps">of an</span> Exhibition
        </h1>
        <p className="lead lead--large">
          The exhibition <i>Nature’s Nation: American Art and Environment</i>{' '}
          considers how American artists have reflected and influenced
          environmental understanding.
        </p>
        <p className="lead lead--tight">
          <strong>
            But what is the environmental impact of the exhibition itself?
          </strong>
        </p>
        <p>
          Creating an exhibition involves balancing fundamental tensions between
          caring for works of art, reaching a wide audience while advancing
          specialized scholarship and research, and maintaining fiscal
          responsibility—as well as an increasing commitment to environmental
          sustainability.
        </p>
        <p className="lead text-green">
          Using <i>Nature’s Nation</i> as a point of reference, this website
          investigates some of the critical planning decisions and their
          corresponding environmental implications.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <Link to="/gathering-the-objects">
              <b>Gathering the Objects</b>
              <Img fixed={data.objects.childImageSharp.fixed} />
            </Link>
          </li>
          <li>
            <Link to="/designing-the-exhibition">
              <b>Designing the Exhibition</b>
              <Img fixed={data.exhibition.childImageSharp.fixed} />
            </Link>
          </li>
          <li>
            <Link to="/creating-the-catalogue">
              <b>Creating the Catalogue</b>
              <Img fixed={data.catalogue.childImageSharp.fixed} />
            </Link>
          </li>

          <li>
            <Link to="/the-life-of-an-object">
              <b>The Life of an Object</b>
              <Img fixed={data.life.childImageSharp.fixed} />
            </Link>
          </li>

          <li>
            <Link to="/finding-the-balance">
              <b>Finding the Balance</b>
              <Img fixed={data.balance.childImageSharp.fixed} />
            </Link>
          </li>
        </ol>
      </div>
      <div className="start__image" />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    objects: file(relativePath: { regex: "/start-objects.jpg/" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    exhibition: file(relativePath: { regex: "/start-exhibition.jpg/" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    catalogue: file(relativePath: { regex: "/start-catalogue_new.jpg/" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    life: file(relativePath: { regex: "/start-life.jpg/" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    balance: file(relativePath: { regex: "/start-balance-crop.jpg/" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default StartPage
